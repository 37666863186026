import styled from '@emotion/styled';
import clsx from 'clsx';

import { injectGlobal } from '@emotion/css';
import { ReactNode } from 'react';

const OuterWrapper = styled.div`
    position: relative;

    max-width: 192rem;
    height: 108rem;

    flex: 1;

    overflow: hidden;

    &.portrait {
        width: 108rem;
        height: 192rem;
    }
`;

const InnerWrapper = styled.div<{ backgroundImage?: string }>`
    width: 100%;
    height: 100%;

    background-image: url(${(props) => props.backgroundImage});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
`;

export interface AspectRatioKeeperProps {
    children: ReactNode;
    isPortrait?: boolean;
    backgroundImage?: string;
}

export const AspectRatioKeeper = (props: AspectRatioKeeperProps) => {
    const { children, isPortrait = false, backgroundImage } = props;

    if (isPortrait) {
        injectGlobal`
      html, body {
        font-size: calc((100vh / 1920) * 10);
      }
    `;
    }
    return (
        <OuterWrapper className={clsx({ portrait: isPortrait })}>
            <InnerWrapper backgroundImage={backgroundImage}>
                {children}
            </InnerWrapper>
        </OuterWrapper>
    );
};
