import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { WebSocketProvider } from '@shared/ui/contexts';

import * as Sentry from '@sentry/react';
import { App } from './app/app';
// App default styles
import '@shared/assets/styles/style.css';

import { GameContextProvider } from './app/contexts/game-context';

Sentry.init({
    dsn: 'https://393e28267c1c2b39c6621bfd4755ab04@sentry.animo.net/2',
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/demo\.animo\.net/,
            ],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const url = new URLSearchParams(window.location.search);
const token = url.get('token');

root.render(
    <StrictMode>
        <WebSocketProvider token={token || ''} useTokenHost>
            <GameContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </GameContextProvider>
        </WebSocketProvider>
    </StrictMode>
);
