import { useMemo } from 'react';
import styled from '@emotion/styled';
import clsx from 'clsx';

import { PlayerAction, IPlayerScore } from '@blackjack/models';

import BustSVG from '@blackjack/assets/icons/Bust.svg';

import { CurrentState } from '../contexts/game-context';

import { getLastAction } from '../utils/get-last-action';

const Container = styled.div<{ action?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: -2rem;

  .name {
    color: #ffffff;
    font-size: 2.4rem;
    font-family: 'Chakra Petch';
    text-transform: uppercase;
    margin-bottom: -1.2rem;
  }

  .seat {
    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: 14.6rem;
    width: 20.4rem;

    svg {
      height: 3rem;
      width: 6rem;
      color: black;
    }

    .gap {
      width: 0.4rem;
      background-color: black;
    }

    .split {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 9.7rem;
      width: 10rem;
      background-color: #ffffff;
      border: 0.5rem solid #ffffff;

      .action {
        color: #202020;
        font-size: 5.6rem;
        font-family: 'Chakra Petch';
      }

      &.left-split {
        border-top-left-radius: 5.35rem;
        border-bottom-left-radius: 5.35rem;
      }

      &.right-split {
        border-top-right-radius: 5.35rem;
        border-bottom-right-radius: 5.35rem;
      }

      &.active {
        height: 9.7rem;
        background-color: #032799;
        border: ${({ action }) => {
          switch (action) {
            case '-':
              return '0.5rem solid #FF1C1C';
            case '+':
              return '0.5rem solid #01FF39';
            default:
              return '0.5rem solid #032799';
          }
        }};
        .action {
          color: ${({ action }) => {
            switch (action) {
              case '-':
                return '#FF1C1C';
              case '+':
                return '#01FF39';
              default:
                return 'black';
            }
          }};
        }
      }
    }
  }
`;

export interface SplitBoxProps {
  hand: number;
  isSplit: boolean;
  playerLastActions: [PlayerAction | null, PlayerAction | null];
  playerScores: IPlayerScore[];
  playerName: string;
  seatNumber: number;
  currentState: CurrentState;
}

export const SplitBox = (props: SplitBoxProps) => {
  const {
    hand,
    isSplit,
    playerLastActions,
    playerName,
    playerScores,
    seatNumber,
    currentState,
  } = props;

  const current_seat = currentState.seat_id;

  const state = useMemo(() => {
    if (isSplit && current_seat === seatNumber && hand === 0)
      return 'playing-left';
    if (
      (isSplit &&
        current_seat === seatNumber &&
        playerLastActions[0] === 'STAND') ||
      (isSplit && current_seat === seatNumber && playerScores[0].min === 21)
    )
      return 'playing-right';
  }, [
    hand,
    isSplit,
    current_seat,
    seatNumber,
    playerLastActions,
    playerScores,
  ]);

  const leftBust = getLastAction(playerScores, playerLastActions, 0) === 'BUST';

  const rightBust =
    getLastAction(playerScores, playerLastActions, 1) === 'BUST';

  return (
    <Container
      action={
        state === 'playing-left'
          ? getLastAction(playerScores, playerLastActions, 0)
          : getLastAction(playerScores, playerLastActions, 1)
      }
      className={state}
    >
      <div data-testId="name" className="name">
        {playerName}
      </div>
      <div data-testId="seat" className="seat">
        <div
          data-testId="left-split"
          className={clsx(
            `split left-split`,
            state === 'playing-left' && 'active'
          )}
        >
          {leftBust ? (
            <BustSVG />
          ) : (
            <div data-testId="left-action" className="action">
              {playerLastActions[0] !== PlayerAction.SPLIT &&
                getLastAction(playerScores, playerLastActions, 0)}
            </div>
          )}
        </div>

        <div className="gap"></div>

        <div
          data-testId="right-split"
          className={clsx(
            `split right-split`,
            state === 'playing-right' && 'active'
          )}
        >
          {rightBust ? (
            <BustSVG />
          ) : (
            <div data-testId="right-action" className="action">
              {getLastAction(playerScores, playerLastActions, 1)}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};
